import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ULink from "./uLink"
import UniFrame from './uFrame'
import UniBackOffice from './uBo'
import URedirect from './uRedirect'
import 'css-style'
import './App.css'
import {urlAlert} from "api-methods"

function App() {

    if (document.location.pathname.replace(/[/@._A-Za-z0-9:%]+/g, "") !== "") {urlAlert('url-pathname-char').then()}
    if (document.location.search.replace(/[?=._:%/&A-Za-z0-9]+/g, "") !== "") {urlAlert('url-search-char').then()}

    return <Routes>
        <Route path="/remotes/antivirus" element={<UniFrame/>} />
        <Route path="/static/*" element={<UniFrame/>} />
        <Route path="/static" element={<UniFrame/>} />
        <Route path="/manager/*" element={<UniBackOffice/>} />
        <Route path="/manager" element={<UniBackOffice/>} />

        <Route path="/help/*" element={<URedirect title={"Инструкция"} />} />
        <Route path="/help" element={<URedirect title={"Инструкция"} />} />
        <Route path="/instruction/*" element={<URedirect title={"Инструкция"} />} />
        <Route path="/instruction" element={<URedirect title={"Инструкция"} />} />
        <Route path="/*" element={<ULink />} />
    </Routes>
}

export default App
