import React, {useState} from "react"
import {stringOsNameTrim, urlGuidSearch, osShow, osShowT, stringDeleteYear} from 'helper-func'
import {apiLinkGuid, apiLinkGuidCheck} from 'api-methods'

function App() {

    // 1. params
    const [loader, setLoader] = useState(true)
    const [notFound, setNotFound] = useState(false)
    const [reqOne, setReqOne] = useState(false)
    const [productName, setProductName] = useState('')
    const [description, setDescription] = useState('')
    const [distrId, setDistrId] = useState(0)
    const [osPrimary, setOsPrimary] = useState([])
    const [osOther, setOsOther] = useState([])
    const [subsStatus, setSubsStatus] = useState(false)
    let disrtLink =['win','and','ios','mac','lin']
    let iconArr = [
        {icon: 'MainPage__product-iconWindows', text: 'для Windows'},
        {icon: 'MainPage__product-iconAndroid', text: 'для Android'},
        {icon: 'MainPage__product-iconIos', text: 'для iOS'},
        {icon: 'MainPage__product-iconMac', text: 'для Mac'},
        {icon: 'MainPage__product-iconLinux', text: 'для Linux'}
    ]
    const [keyList, setKeyList] = useState({})

    // 2. JSX button
    const ButtonOs = ((item) => {
        let indexOs = disrtLink.indexOf(stringOsNameTrim(item.os).split('_')[0])
        if (indexOs + '' !== '-1') {
            return (<a key={Math.round(new Date().getTime())} className={'MainPage__product-button ' + (item.ptype ? 'bt_width' : '')} target="_blank" rel="noreferrer" href={item.link}>
                <div>
                    <div className={iconArr[indexOs].icon} />
                    <div className="MainPage__product-text">{(item.ptype && item.name !== '') ? item.name : iconArr[indexOs].text}</div>
                </div>
            </a>)
        }

        return ''
    })

    // 3. api
    async function shortLinkData () {
        try {
            await apiLinkGuid(urlGuidSearch(1)).then(data => {
                if (data.code === 0) {
                    apiLinkGuidCheck(data)
                }
                if (data.code === 0 && !!data.data.distrList) {
                    let apiProductName = data.data.productName
                    if (apiProductName.split(')').length > 1) {apiProductName = apiProductName.split(')')[0] + ')'}
                    if (apiProductName.split('day').length > 1 || apiProductName.split('trial').length > 1) {apiProductName = apiProductName.split('(')[0].trim()}
                    setProductName(stringDeleteYear(apiProductName))
                    setDescription(data.data.description)
                    setDistrId(data.data.distributor_id)
                    setSubsStatus(data.data.status === 'active')

                    let userOs = osShow()
                    let osPrimaryTemp = []
                    let osOtherTemp = []
                    if (!!data.data.distrList && Object.keys(data.data.distrList).length > 0) {
                        (data.data.distrList).forEach((item, indexKey) => {
                            if (disrtLink.indexOf(stringOsNameTrim(item.key).split('_')[0]) === userOs) {osPrimaryTemp.push(item)} else {osOtherTemp.push(item)}
                        })
                    }
                    setOsPrimary(osPrimaryTemp)
                    setOsOther(osOtherTemp)

                    if (data.data.keyList && Object.keys(data.data.keyList).length > 0) {
                        setKeyList(data.data.keyList)
                    }
                } else {
                    setNotFound(true)
                }
                setLoader(false)
            })
        } catch (e) {
            setNotFound(true)
            setLoader(false)
        }
    }

    // 3. api
    if (!reqOne) {setReqOne(true);shortLinkData().then()}

    // 4. Kasper key // return (name.indexOf('Kaspersky Total Security') > -1)
    function nameInButton (name = '') {return true}

    // 5. KeyList
    const KeyList = () => {
        return <div>
            {!!keyList && keyList.map((item, i) => {
                if ((['key','login','pass']).indexOf(item.name) > -1) {
                    return <div key={i} className="MainPage__product-link"><div><div className="MainPage__product-download-title"><b>{item.name === 'key' ? 'Ключ' : (item.name === 'login' ? 'Логин' : 'Пароль')}: {item.value}</b></div></div></div>
                } else {
                    return null
                }
            })}
        </div>
    }

    return (
        <div className="App">
            <div className="MainPage__header"><div className="MainPage__width"><div className="MainPage__product-sl"><div className="MainPage__Logo" /></div></div></div>
            <div className="MainPage__content">
                <div className="MainPage__width">
                    {loader ? <div className="preLoader"><div className="loader" /></div> : (
                        (notFound || productName === '' ) ? <div className="MainPage__notFound">404</div> : (
                            <div className="MainPage__product">
                                <div className="MainPage__product-name">{productName}</div>
                                {description !== '' && (<div className="MainPage__product-title">{description}</div>)}
                                {(Object.keys(osPrimary).length > 0 && subsStatus) && (
                                    <div>
                                        <div className="MainPage__product-title">Мы определили вашу систему как {osShowT(osShow())}</div>
                                        <KeyList />
                                        <div className="MainPage__product-download">
                                            <div className="MainPage__product-download-title">Скачайте дистрибутив для нее:</div>
                                            {osPrimary.map((item, i) => {return (<ButtonOs key={i} os={(item.key)} link={item.value} name={item.name} ptype={nameInButton(productName)} />)})}
                                        </div>
                                    </div>
                                )}
                                {(Object.keys(osOther).length > 0 && subsStatus) && (
                                    <div className="MainPage__product-download">
                                        {(Object.keys(osPrimary).length > 0) ? '' : <div><KeyList /></div>}
                                        <br />
                                        <div className="MainPage__product-download-title">{(Object.keys(osPrimary).length > 0) ? 'Приложение для других платформ:' : 'Выберите корректный дистрибутив:'}</div>
                                        <div className="MainPage__product-link">{osOther.map((item, i) => {return (<ButtonOs key={i} os={(item.key)} link={item.value} name={item.name} ptype={nameInButton(productName)} />)})}</div>
                                        {(distrId + '' === '17') && <div className="MainPage__product-bottom">Установите приложение Kaspersky на смартфон или компьютер. Ключ уже встроен, вводить его не требуется</div>}
                                    </div>
                                )}
                                {!subsStatus && <div className="MainPage__product-link"><div><div className="MainPage__product-download-title"><b>Подписка не активна</b></div></div></div>}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

export default App
