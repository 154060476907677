import React from "react"
import U404 from "./u404"
import {partnerHelpLink, stringReplaceMask, urlGuidSearch} from 'helper-func'

function App(props) {
    document.title = props.title
    let redirectUrl = partnerHelpLink(stringReplaceMask(urlGuidSearch(2), 'url'))
    if (redirectUrl !== '') {window.location.href = redirectUrl}
    return <div className="App">{redirectUrl === '' && <U404 />}</div>
}

export default App
